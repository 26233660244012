import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

import home from "../views/home/Index.vue";

const routes = [
  {
    path: '/',
    name: 'homev',
    component: HomeView
  },
  { 
    path: '/home', 
    name: 'home',
    redirect: '/home/disease',
    components: { 
      home: home 
    },
    children: []
  },
  {
    path: '/exam',
    name: 'exam',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/exam/Index.vue')
  },
  { 
    path: '/home/acupoint/detail', 
    name: 'apdetail', 
    component: () => import( '../views/home/acupoint/Detail.vue')
  },
  { 
    path: '/home/disease/detail', 
    name: 'dedetail', 
    component: () => import( '../views/home/disease/Detail.vue')
  },
  { 
    path: '/home/graphic/detail', 
    name: 'grdetail', 
    component: () => import( '../views/home/graphic/Detail.vue')
  },
  { 
    path: '/home/herb/detail', 
    name: 'herbdetail', 
    component: () => import( '../views/home/herb/Detail.vue')
  },{ 
    path: '/home/symptom/detail', 
    name: 'symptomdetail', 
    component: () => import( '../views/home/symptom/Detail.vue')
  },{ 
    path: '/home/symptom/dongs', 
    name: 'dongsdetail', 
    component: () => import( '../views/home/symptom/Dongs.vue')
  },{ 
    path: '/video/play', 
    name: 'play', 
    component: () => import( '../views/home/video/Play.vue')
  },{ 
    path: '/home/ai/graph', 
    name: 'graph', 
    component: () => import( '../views/home/ai/Graph.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
